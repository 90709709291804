.menuContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1920px;
}

.navbar {
  width: 100%
}

.logoutContainer {
  display: flex;
}

.breadCrumbsContainer {
  width: auto;
}

.breadCrumbsContainer nav {
  margin: 0 10px;
}
