.drugContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sectionedContainer {
  display: flex;
  justify-content: space-evenly;
}

.sectionOne {
  flex: 1;
  margin: 10px 20px 0 0;
}

.sectionTwo {
  flex: 1;
  margin: 10px 0 0 20px;
}

.mdb-dataTable-head ::before,  .mdb-dataTable-head ::after{
  left: 0rem !important;
}

.form-control, .input-group-text {
  margin: .2rem 0;
}

.form-outline.datepicker {
  margin: .6rem 0;
}

.input-group-text {
  min-width: 150px;
}

.drugInfoNav {
  box-shadow: none;
  justify-content: flex-end;
}

.tab-content {
  margin-top: 1rem;
}

.saveButton {
  margin-left: .5rem;
}

.drugInfoContainer {
  width: auto;
}

.drugTable {
  max-width: 1440px;
  overflow: scroll;
}

.fdaSearchContainer {
  align-content: flex-end;
  display: flex;
  justify-content: flex-end;

}
