.rbsContainer {
  display: flex;
  justify-content: space-evenly ;
  margin-top: 100px;
  width: 100%;
}

.fa-user-friends {
  font-size: 6em;
}

.fa-pallet {
  font-size: 6em;
}

.fa-pills {
  font-size: 6em;
}

.rbsContainer .btn {
  margin: 0 10px;
}
